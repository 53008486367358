document.addEventListener('DOMContentLoaded', () => {
  $('.js-toggle-archive-button').on('click', (obj) => {
    data = $(obj.target).data()
    $.ajax({
      method: 'PATCH',
      url: `/assessments/${data.id}.json`,
      data: {archive: (data.archive ? '0' : '1')}
    }).done(( resp ) => {
      $(`#yula-${resp.assessment.id}`).fadeOut(1000)
      
      // decrement the count by 1
      $('#doc-count-value').text(
        $('#doc-count-value').text() * 1 - 1
      )

      archived_link_exists = $('#archived-link').length  ? true : false

      if (!archived_link_exists){
        $('#summary').prepend('<div id="archived-link"><a href="/assessments?archive=1">View your archived pages</a></div>')
      }

      on_archives_page = window.location.search.match(/.*archive=1.*/)

      if( $('#doc-count-value').text()*1 === 0 && on_archives_page){
        window.location.assign('/assessments')
      }

    })
  })

  if( gon.evals){
    gon.evals.forEach((eva) => {
      const regex = new RegExp(eva.rule.criteria, 'i');
      console.dir(regex)

      elId = `eval-${eva.id}`
      el = document.getElementById(elId)
      console.dir(el)

      sentence = el.children[0].innerText
      // debugger
      console.dir(sentence.match(regex)[0])

      sentence = sentence.replace(regex, '<span class="found">$&</span>') // $& means the whole matched string
      console.log(sentence)
      el.children[0].innerHTML = sentence
    })
  }
})