window.fbAsyncInit = function() {
  FB.init({
    appId      : gon.facebook_id,
    cookie     : true,
    xfbml      : true,
    version    : 'v16.0'
  });

  FB.AppEvents.logPageView();
};

(function(d, s, id){
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));

document.addEventListener("DOMContentLoaded", function(event) { 
  window.YFBLogin = (uu, aa, bb, cc) => {
    console.log('On')
  }
});