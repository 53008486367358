// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('jquery')
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('../components/assessment.js')
require('../facebook.js')
require('@popperjs/core')
require('bootstrap')

import { createPopper } from '@popperjs/core'
import Bootstrap from 'bootstrap'

document.addEventListener('DOMContentLoaded', () => {
  const account = document.querySelector('#account')
  const accountMenu = document.querySelector('#account-menu')
  const accountMenuLink = document.querySelector('#account-menu-link')
  window.pop = createPopper(account, accountMenu, {
    placement: 'bottom-start',
    onFirstUpdate: state => console.log('Popper positioned on', state.placement)
  })
});

// import Chart from 'chart.js'


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
